import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="animated-number"
export default class AnimatedNumber extends Controller {
  static values = {
    start: Number,
    end: Number,
    duration: Number,
    decimal: Boolean,
    lazyThreshold: Number,
    lazyRootMargin: {
      type: String,
      default: "0px",
    },
    lazy: Boolean,
  };

  connect() {
    this.lazyValue ? this.lazyAnimate() : this.animate();
    console.log(this.endValue);
  }

  animate() {
    let startTimestamp = null;

    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;

      const elapsed = timestamp - startTimestamp;
      const progress = Math.min(elapsed / this.durationValue, 1);

      if (this.decimalValue == true) {
        this.element.innerHTML = (
          Math.floor(
            (progress * (this.endValue - this.startValue) + this.startValue) * 10,
          ) / 10
        ).toString();
      } else {
        this.element.innerHTML = Math.floor(
          progress * (this.endValue - this.startValue) + this.startValue,
        ).toString();
      }

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }

  lazyAnimate() {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.animate();

          observer.unobserve(entry.target);
        }
      });
    }, this.lazyAnimateOptions);

    observer.observe(this.element);
  }

  get lazyAnimateOptions() {
    return {
      threshold: this.lazyThresholdValue,
      rootMargin: this.lazyRootMarginValue,
    };
  }
}
